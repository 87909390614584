<template>
  <v-row class="ms-1 pt-3 mb-1">
    <v-col cols="12" lg="4">
      <v-row>
        <v-col cols="2" md="1" sm="1" class="text-left">
          <v-progress-circular
            :rotate="-90"
            :size="50"
            :width="5"
            :value="33.33 * currentStep"
            color="teal"
          >
            {{ currentStep }}/3
          </v-progress-circular>
        </v-col>
        <v-col cols="6">
          <v-row class="ml-1 mt-1 grey--text text--darken-4">
            <span>{{ $t(stepLabel[currentStep]) }}</span>
          </v-row>
          <v-row class="ml-1">
            <span
              v-if="stepLabel[currentStep + 1] != undefined"
              class="grey--text text--darken-1 caption"
            >
              Next: {{ $t(stepLabel[currentStep + 1]) }}</span
            >
          </v-row>
        </v-col>
        <v-col
          cols="4"
          sm="5"
          md="5"
          xs="5"
          class="text-right float-right"
          :class="!$vuetify.breakpoint.mdAndUp ? 'my-auto' : ''"
        >
          <v-btn
            class="mr-4 text-none float-right"
            outlined
            small
            color="blue"
            @click="$emit('cancelTicket')"
          >
            {{ $t("cancel") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
export default {
  props: {
    currentStep: {
      type: Number,
      default: () => 0,
    },
  },
  data() {
    return {
      stepLabel: {
        1: "excavatingLocation",
        2: "excavationDetails",
        3: "confirmAndSubmit",
      },
    };
  },
};
</script>
