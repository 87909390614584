var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { staticClass: "ms-1 pt-3 mb-1" },
    [
      _c(
        "v-col",
        { attrs: { cols: "12", lg: "4" } },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  staticClass: "text-left",
                  attrs: { cols: "2", md: "1", sm: "1" },
                },
                [
                  _c(
                    "v-progress-circular",
                    {
                      attrs: {
                        rotate: -90,
                        size: 50,
                        width: 5,
                        value: 33.33 * _vm.currentStep,
                        color: "teal",
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.currentStep) + "/3 ")]
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c(
                    "v-row",
                    { staticClass: "ml-1 mt-1 grey--text text--darken-4" },
                    [
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t(_vm.stepLabel[_vm.currentStep]))),
                      ]),
                    ]
                  ),
                  _c("v-row", { staticClass: "ml-1" }, [
                    _vm.stepLabel[_vm.currentStep + 1] != undefined
                      ? _c(
                          "span",
                          { staticClass: "grey--text text--darken-1 caption" },
                          [
                            _vm._v(
                              " Next: " +
                                _vm._s(
                                  _vm.$t(_vm.stepLabel[_vm.currentStep + 1])
                                )
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]),
                ],
                1
              ),
              _c(
                "v-col",
                {
                  staticClass: "text-right float-right",
                  class: !_vm.$vuetify.breakpoint.mdAndUp ? "my-auto" : "",
                  attrs: { cols: "4", sm: "5", md: "5", xs: "5" },
                },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-4 text-none float-right",
                      attrs: { outlined: "", small: "", color: "blue" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("cancelTicket")
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("cancel")) + " ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }